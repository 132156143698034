import React, { useState, useRef, useEffect } from 'react';
import "./video.css"; // Import your CSS file
import thumbnail from "../../assets/image/icons/fives.jpg";
import logo from "../../assets/image/GT9Vaucv-logo_trans.png";

const MyVideoComponent = () => {
  const hideAndPlay = () => {
    const poster = document.getElementById("poster-container");
    const vid = document.getElementById("video");
    poster.style.display = "none";
    vid.play();
  };

  const loadPoster = () => {
    const poster = document.getElementById("poster-container");
    poster.style.display = "block";
  };

  const videoRef = useRef(null);
  const contextMenuRef = useRef(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  const handleMenuItemClick = (action) => {
    const video = videoRef.current;
    const contextMenu = contextMenuRef.current;

    switch (action) {
      case 'play':
        video.play();
        break;
      case 'pause':
        video.pause();
        break;
      case 'stop':
        video.pause();
        video.currentTime = 0;
        loadPoster();
        break;
      default:
        break;
    }

    contextMenu.style.display = 'none';
    setContextMenuVisible(false);
  };

  useEffect(() => {
    const video = videoRef.current;
    const contextMenu = contextMenuRef.current;

    const showContextMenu = (x, y) => {
      contextMenu.style.display = 'block';
      contextMenu.style.left = `calc(${x}px - 18vw)`;
      contextMenu.style.top = `calc(${y}px - 18%)`;
    };

    const hideContextMenu = () => {
      contextMenu.style.display = 'none';
      setContextMenuVisible(false);
    };

    const handleContextMenu = (e) => {
      e.preventDefault();
      showContextMenu(e.clientX, e.clientY);
      setContextMenuVisible(true);
    };

    const handleClickOutside = (e) => {
      if (contextMenuVisible && !contextMenu.contains(e.target)) {
        hideContextMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);

    video.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      video.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [contextMenuVisible]);

  return (
    <div>
      <div id="bc-video-html" className="html_video_container">
        <div id="poster-container">
          <div
            id="poster"
            className="html_video_container-poster"
            style={{
              backgroundImage:
              `url(${thumbnail})`
            }}
          ></div>
          <div className="description-container description-container_desktop">
            <a href="https://clearasdayproductions.co.uk/projects/fives-soccer/" target="_blank"><img src={logo} alt="" className="logowidth" /></a>
            <h1 className="html_video_title">FIVES Soccer</h1>
            <p className="html_video_description">
            Here is a short promo we produced for Fives Soccer to be used both as a display video in the sports centre reception and as an online promo for the venue.</p>
            <a href="https://www.football5s.com/" target="_blank">Visit Website</a>
          </div>
          <div id="playVid" className="play-container" style={{backgroundColor:'#06b5cc'}} onClick={hideAndPlay}>
            <span>
              <svg
                stroke="currentColor"
                fill="#fff"
                strokeWidth="0"
                viewBox="0 0 448 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <video
        ref={videoRef}
        id="video"
        controls
        src="https://vz-689f2ec6-ffe.b-cdn.net/a56dbebc-1ece-48e6-ba8d-5320e4becda2/play_720p.mp4"
        onEnded={loadPoster}
        controlsList="nodownload"
        ></video>
            <div className='watermark'>
            <a href="https://clearasdayproductions.co.uk/projects/fives-soccer/" target="_blank"><img src={logo} alt="" className="logowidth" /></a>
        </div>          
            <div
                ref={contextMenuRef}
                className={`context-menu ${contextMenuVisible ? 'visible' : ''}`}
            >
                <ul>
                <li>About <a href="#https://www.digma.io" target="_bank">Digma</a></li>
                <li onClick={() => handleMenuItemClick('play')}>Play</li>
                <li onClick={() => handleMenuItemClick('pause')}>Pause</li>
                <li onClick={() => handleMenuItemClick('stop')}>Stop</li>
                </ul>
            </div>          
        </div>
        <div className="description-container description-container_mobile">
        <h1 className="html_video_title">FIVES Soccer</h1>
            <p className="html_video_description">
            Here is a short promo we produced for Fives Soccer to be used both as a display video in the sports centre reception and as an online promo for the venue.</p>
            <a href="https://www.football5s.com/" target="_blank">Visit Website</a>

        </div> 
    </div>
  );
};

export default MyVideoComponent;
